import axios from 'axios';
import global from '@/components/Global.vue';

let doartSystemBase = global.doartSystemBase

// 分页查询系统角色
export const querySysRoleList = params => { return axios.post(doartSystemBase + '/role/querySysRoleList', params)};
// 添加/修改系统角色
export const saveOrModifySysRole = params => { return axios.post(doartSystemBase + '/role/saveOrModifySysRole', params)};
// 查询系统角色
export const querySysRoleById = params => {

    return axios({

        url: doartSystemBase + '/role/querySysRoleById',
        method: 'POST',
        ping: true,
        data: params
    });
};
// 删除系统角色
export const removeSysRole = params => {

    return axios({

        url: doartSystemBase + '/role/removeSysRole',
        method: 'POST',
        ping: true,
        data: params
    });
};
// 查询当前登陆人可分配的菜单列表
export const queryAssignableMenuList = params => { return axios.post(doartSystemBase + '/role/queryAssignableMenuList', params)};

// 查询当前登陆用户可用角色信息
export const queryCurrentUserAvailableRoleInfo = () => {return axios.post(doartSystemBase + '/role/queryCurrentUserAvailableRoleInfo')};

// 查询指定组织机构下可用角色信息
export const queryAvailableRoleInfoByOrgId = (params) => {return axios.post(doartSystemBase + '/role/queryAvailableRoleInfoByOrgId', params)};

