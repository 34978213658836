<template>
    <el-dialog :title="detailDialogTitle" :visible.sync="detailDialogVisible" top="0" center
               @close="closeSysUserDetailPage" width="850px" :close-on-click-modal="false" :destroy-on-close=true>
        <el-form :model="sysUserDto" :rules="formRules" ref="sysUserForm" label-width="120px" size="mini">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="归属机构" prop="orgId">
                        <doart-select-tree :width="190" :data="orgData" :checkedKeys="orgCheckedKeys"
                            @change="handleSelectOrgChangeEvent" class="form-item-class"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="角色" prop="roleId">
                        <el-select v-model="sysUserDto.roleId" placeholder="请选择用户角色" class="form-item-class">
                            <el-option v-for="item in roleData" :key="item.id" :label="item.roleName" :value="item.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="账号" prop="loginName">
                        <el-input :disabled="sysUserDto.id !== null" v-model="sysUserDto.loginName"
                              autocomplete="off" maxlength="50" class="form-item-class"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="名称" prop="userName">
                        <el-input v-model="sysUserDto.userName" autocomplete="off" class="form-item-class"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="密码" prop="password" :error="passwordError">
                        <el-input v-model="sysUserDto.password" autocomplete="off" maxlength="50" class="form-item-class"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="确认密码" prop="confirmPassword" :error="confirmPasswordError">
                        <el-input v-model="sysUserDto.confirmPassword" autocomplete="off" maxlength="50" class="form-item-class"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="sysUserDto.email" autocomplete="off" maxlength="50" class="form-item-class"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="温度单位" prop="temperatureUnit">
                        <el-select v-model="sysUserDto.temperatureUnit" placeholder="请选择温度单位" class="form-item-class">
                            <el-option key="1" label="摄氏度（℃）" value="1"/>
                            <el-option key="2" label="华氏度（℉）" value="2"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="国家区号" prop="areaCode">
                        <el-input v-model="sysUserDto.areaCode" autocomplete="off" maxlength="50" class="form-item-class"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="电话" prop="phone">
                        <el-input v-model="sysUserDto.phone" autocomplete="off" maxlength="50" class="form-item-class"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="是否禁用" prop="status">
                        <el-select v-model="sysUserDto.status" class="form-item-class">
                            <el-option :key="false" label="启用" :value="false"/>
                            <el-option :key="true" label="禁用" :value="true"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="用户头像">
                        <el-upload action="" list-type="picture-card"
                            :auto-upload="false"
                            :on-preview="handleImagePreviewEvent"
                            :on-remove="handleImageRemoveEvent"
                            :on-change="handleImageChangeEvent"
                            :on-exceed="handleImageExceedEvent"
                            :multiple="false"
                            :limit="1"
                            :file-list="imageList">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="imageDialogVisible" :modal="false">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="mini" type="primary" @click="saveOrModifySysUserInfo">确定</el-button>
            <el-button size="mini" @click="detailDialogVisible = false">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import global from "@/components/Global";
import message from "@/utils/message";
import DoartSelectTree from "@/components/doart-select-tree/doart-select-tree";
import {queryCurrentUserOrgTree} from "@/api/sys/org/sysOrgApi";
import {queryAvailableRoleInfoByOrgId, queryCurrentUserAvailableRoleInfo} from "@/api/sys/roleApi";
import {querySysUserInfoById, createSysUserInfo, modifySysUserInfo} from '@/api/sys/userApi';

export default {

    name: "sys-user-detail",
    components: {

        DoartSelectTree
    },
    data() {

        return {

            detailDialogTitle: '',
            detailDialogVisible: false,
            orgData: [],
            orgCheckedKeys: [],
            roleData: [],
            imageDialogVisible: false,
            dialogImageUrl: '',
            imageList: [],
            sysUserDto: {

                id: null,
                orgId: '',
                roleId: '',
                loginName: '',
                userName: '',
                password: '',
                confirmPassword: '',
                email: '',
                temperatureUnit: '1',
                areaCode: '',
                phone: '',
                status: false,
                headSculpture: '',
                removeImagePath: ''
            },
            formRules: {

                orgId: [{required: true, message: '机构不可为空', trigger: 'blur'}],
                roleId: [{required: true, message: '角色不可为空', trigger: 'blur'}],
                loginName: [{required: true, message: '账号不可为空', trigger: 'blur'}],
                userName: [{required: true, message: '名称不可为空', trigger: 'blur'}],
                email: [{required: true, message: '邮箱不可为空', trigger: 'blur'}],
            },
            passwordError: '',
            confirmPasswordError: ''
        };
    },
    methods: {

        openCreateSysUserInfoPage() {

            this.queryOrgInfoList();
            this.queryRoleInfoList();
            this.detailDialogVisible = true;
            this.detailDialogTitle = '新增用户';
        },
        openEditSysUserInfoPage(id) {

            this.sysUserDto.id = id;
            this.detailDialogTitle = '编辑用户';
            this.detailDialogVisible = true;

            let p1 = this.queryOrgInfoList();
            let p2 = this.queryRoleInfoList();
            let promiseArr = [p1, p2];
            Promise.all(promiseArr).then(() => {

                this.querySysUserInfoById();
            }).catch(err => {

                console.error(err);
                message.error('请求失败,请稍后再试');
            });
        },
        closeSysUserDetailPage() {

            this.sysUserDto.id = null;
            this.orgData = [];
            this.orgCheckedKeys = [];
            this.roleData = [];
            this.imageList = [];
            this.$refs.sysUserForm.resetFields();
        },
        queryOrgInfoList() {

            return new Promise((resolve, reject) => {

                queryCurrentUserOrgTree().then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.orgData = [res.data];
                        resolve(res.msg);
                    } else {

                        reject(res.msg);
                    }
                }).catch(err => {

                    reject('请求失败,请稍后再试');
                    console.error(err);
                });
            });
        },
        queryRoleInfoList() {

            return new Promise((resolve, reject) => {

                queryCurrentUserAvailableRoleInfo().then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.roleData = res.data;
                        resolve(res.msg);
                    } else {

                        reject(res.msg);
                    }
                }).catch(err => {

                    reject('请求失败,请稍后再试');
                    console.error(err);
                });
            });
        },
        querySysUserInfoById() {

            let params = {

                id: this.sysUserDto.id
            };
            this.imageList = [];
            querySysUserInfoById(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.sysUserDto.id = res.data.id;
                    this.sysUserDto.orgId = res.data.orgId;
                    this.orgCheckedKeys = [res.data.orgId];
                    this.sysUserDto.roleId = res.data.roleId;
                    this.sysUserDto.loginName = res.data.loginName;
                    this.sysUserDto.userName = res.data.userName;
                    this.sysUserDto.email = res.data.email;
                    this.sysUserDto.temperatureUnit = res.data.temperatureUnit;
                    this.sysUserDto.areaCode = res.data.areaCode;
                    this.sysUserDto.phone = res.data.phone;
                    this.sysUserDto.status = res.data.status;
                    this.sysUserDto.headSculpture = res.data.headSculpture;
                    if (res.data.headSculpture !== '') {

                        this.imageList = [{

                            name: 0,
                            url: res.data.headSculpture
                        }];
                    }
                }
            });
        },
        saveOrModifySysUserInfo() {

            this.$refs.sysUserForm.validate((valid) => {

                let passwordFlag = true;
                if (this.sysUserDto.id != null) {

                    // 修改
                    if (this.sysUserDto.password === '' && this.sysUserDto.confirmPassword === '') {

                        this.passwordError = '';
                        this.confirmPasswordError = '';
                        passwordFlag = true;
                    } else {

                        passwordFlag = this.validatePassword();
                    }
                    if (!valid || !passwordFlag) {

                        return;
                    }
                    this.detailDialogVisible = true;
                    let params = {

                        dto: this.sysUserDto,
                        fileList: this.imageList
                    }
                    modifySysUserInfo(params).then(res => {


                        if (res.code === global.response_status_success_obj) {

                            this.detailDialogVisible = false;
                            this.$emit('querySysUserInfoList');
                            message.success('用户修改成功');
                        } else {

                            message.error(res.msg);
                        }
                    }).catch(err => {

                        message.error('请求失败,请稍后再试');
                        console.error(err);
                    });
                } else {

                    // 新增
                    if (!valid || !this.validatePassword()) {

                        return;
                    }
                    this.detailDialogVisible = true;
                    let params = {

                        dto: this.sysUserDto,
                        fileList: this.imageList
                    }
                    createSysUserInfo(params).then(res => {

                        if (res.code === global.response_status_success_obj) {

                            this.detailDialogVisible = false;
                            this.$emit('querySysUserInfoList');
                            message.success('用户创建成功');
                        } else {

                            message.error(res.msg);
                        }
                    }).catch(err => {

                        message.error('请求失败,请稍后再试');
                        console.error(err);
                    });
                }
            });
        },
        handleSelectOrgChangeEvent(orgId) {

            this.sysUserDto.orgId = orgId;
            let params = {

                id: orgId
            }
            queryAvailableRoleInfoByOrgId(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.roleData = res.data;
                    this.sysUserDto.roleId = '';
                }
            });
        },
        handleImagePreviewEvent(file) {

            this.dialogImageUrl = file.url;
            this.imageDialogVisible = true;
        },
        handleImageRemoveEvent(file, fileList) {

            if (file.status === 'success') {

                this.imageList = [];
                this.sysUserDto.headSculpture = '';
            }
        },
        handleImageChangeEvent(file, fileList) {

            this.imageList = fileList;
        },
        handleImageExceedEvent() {

            message.error("只能上传一张头像，请先删除原有头像");
        },
        validatePassword() {

            let flag = true;
            if (this.sysUserDto.password === '') {

                this.passwordError = '密码不能为空';
                flag = false;
            } else {

                this.passwordError = '';
                flag = true;
            }
            if (this.sysUserDto.confirmPassword === '') {

                this.confirmPasswordError = '确认密码不能为空';
                flag = false;
            } else {

                this.confirmPasswordError = '';
                flag = true;
            }
            if (this.sysUserDto.password !== '' && this.sysUserDto.confirmPassword !== '') {

                if (this.sysUserDto.password !== this.sysUserDto.confirmPassword) {

                    this.passwordError = '密码与确认密码必须一致';
                    this.confirmPasswordError = '密码与确认密码必须一致';
                    flag = false;
                } else {

                    this.passwordError = '';
                    this.confirmPasswordError = '';
                    flag = true;
                }
            }
            return flag;
        }
    }
}
</script>
<style scoped lang="scss">
.form-item-class {

    width: 214px;
}
</style>
